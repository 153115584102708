.content-main {
  padding: 70px 0; }

.site-map-header {
  display: table;
  width: 100%;
  background: linear-gradient(rgba(0, 0, 255, 0.5), rgba(0, 0, 255, 0.5)), url(/SiteMap.jpg);
  background-position: inherit;
  background-repeat: no-repeat;
  background-size: cover;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center; }
  .site-map-header h1 {
    color: white;
    font-weight: lighter; }

.site-map-menu {
  margin: 0;
  padding: 0; }
  .site-map-menu ul {
    width: max-content;
    display: block; }
    .site-map-menu ul li {
      padding-left: 0;
      padding-top: 32px; }
      .site-map-menu ul li a {
        font-size: 35px;
        font-weight: 600;
        color: #02baef !important; }
  @media (max-width: 767px) {
    .site-map-menu ul {
      width: 100%;
      display: block;
      text-align: center; }
      .site-map-menu ul li {
        padding-left: 0;
        padding-top: 22px; }
        .site-map-menu ul li a {
          font-size: 25px !important;
          font-weight: 600; } }

@media (max-width: 767px) {
  .row {
    margin: 0 !important;
    padding: 0 !important; } }

hr.solid {
  border-top: 1px solid #bbb; }

.ccpa-news {
  margin: 0;
  padding: 0; }
  .ccpa-news li {
    padding-top: 32px;
    padding-left: 0; }
    .ccpa-news li a {
      font-size: 35px;
      font-weight: 600;
      color: #02baef !important; }
  @media (max-width: 767px) {
    .ccpa-news li {
      text-align: center;
      padding-top: 22px;
      padding-left: 0; }
      .ccpa-news li a {
        font-size: 25px;
        font-weight: 600; } }

.news-menu li a {
  font-size: 25px !important;
  padding-top: 20px !important;
  font-weight: normal; }

@media (max-width: 767px) {
  .news-menu li a {
    font-size: 20px !important;
    padding-top: 15px !important;
    font-weight: normal; } }
