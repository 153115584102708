/* overriding ant-design classes **/
.ant-tabs-nav-list {
  margin-left: calc(40px + 15px); }

.ant-pagination {
  margin-top: 20px !important; }

.ant-pagination-item {
  margin: 0 10px !important; }

.ant-pagination-item-active {
  border-left: none !important;
  border-right: none !important;
  border-width: 2px !important;
  border-radius: 0 !important;
  border-top: none !important; }

.ant-pagination-item-link {
  border-radius: 50% !important;
  display: flex !important;
  align-items: center !important;
  height: 35px !important;
  width: 35px !important;
  border-color: #d9d9d9 !important;
  margin: -5px 20px 0 20px; }

/* overriding ant-design classes **/
.date {
  color: #777; }

.article-cover {
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center; }

.big.article-cover {
  min-height: 350px; }

.small.article-cover {
  min-height: 200px; }

.article-page .article-release-info {
  margin-bottom: 10px; }

.article-page .article-cover {
  margin-bottom: 20px; }

.article-page .article-contents p,
.article-page .article-contents li {
  font-size: 14px; }

.article-page .article-contents p {
  margin-bottom: 20px; }

.category-name {
  color: #3366c6;
  font-weight: bold;
  font-size: 12px; }

.article-release-info {
  font-size: 12px; }
  .article-release-info span {
    margin-right: 5px; }

.big.title {
  font-size: 30px; }

.small.title {
  font-size: 18px; }

.author {
  color: #3366c6; }

.title,
.title * {
  color: #000 !important;
  font-weight: bold; }

.label {
  color: #02baef;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 15px;
  text-transform: uppercase;
  display: flex;
  align-items: center; }
  .label span {
    margin-right: calc(20px / 2); }
    .label span svg path {
      fill: #02baef; }

.share-article-block {
  margin-bottom: calc(20px * 2); }
  .share-article-block button {
    margin-right: 20px; }
    .share-article-block button svg {
      height: 50px;
      width: 50px;
      border-radius: 50%; }

/* NEWS PAGE BLOCK STYLES BEGIN **/
.news-page .ant-tabs-nav {
  align-items: center;
  justify-content: center; }
  .news-page .ant-tabs-nav .ant-tabs-nav-wrap {
    max-width: 1140px; }
  .news-page .ant-tabs-nav .ant-tabs-nav-list {
    margin-left: 15px; }
  .news-page .ant-tabs-nav .ant-tabs-tab {
    padding: 12px 15px; }
  .news-page .ant-tabs-nav .ant-tabs-tab-btn {
    color: #797979;
    font-weight: 500; }
  .news-page .ant-tabs-nav .ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #3366c6 !important;
    font-weight: 600 !important; }
  .news-page .ant-tabs-nav .ant-tabs-ink-bar {
    background-color: #3366c6 !important; }
  @media (max-width: 767px) {
    .news-page .ant-tabs-nav .ant-tabs-nav-list {
      margin-left: calc(40px + 15px); } }

.news-page .row:first-child {
  margin-right: 0;
  margin-left: 0; }

.news-page .news-content-block {
  margin: 0 40px !important; }

.news-page .subscription-block {
  height: 100%;
  color: #fff;
  position: relative;
  background-image: url(/69ed2f5b7ffe19d29efa35ad7d13d0d5.png);
  background-repeat: no-repeat;
  background-position: right;
  background-color: #3366c6;
  background-size: contain; }
  @media (max-width: 767px) {
    .news-page .subscription-block {
      background-size: cover;
      width: 100%;
      height: auto; }
      .news-page .subscription-block::before {
        content: "";
        background: linear-gradient(45deg, #3366c6 35%, transparent);
        position: absolute;
        width: 100%;
        height: 100%; } }
  .news-page .subscription-block h3 {
    color: #fff !important; }
  .news-page .subscription-block .subscription-info {
    padding: 35px 40px;
    position: relative; }
    .news-page .subscription-block .subscription-info p {
      font-size: 14px; }
    .news-page .subscription-block .subscription-info form {
      display: flex;
      align-items: center; }
    .news-page .subscription-block .subscription-info button,
    .news-page .subscription-block .subscription-info input {
      border-radius: 30px !important;
      border: none;
      outline: none;
      height: 50px !important;
      padding-left: 30px !important;
      padding-right: 30px !important;
      font-size: 14px !important; }
    @media (min-width: 991px) {
      .news-page .subscription-block .subscription-info .subscription-form .ant-row:first-child {
        width: 40% !important; }
      .news-page .subscription-block .subscription-info .subscription-form .ant-row:nth-child(2) {
        width: auto !important; } }
    .news-page .subscription-block .subscription-info .subscribe-button {
      text-transform: uppercase;
      color: #fff;
      background-color: #02baef; }

.news-page .article-banner-item {
  padding-bottom: calc(20px / 2);
  line-height: 1; }

.news-page .ant-tabs-top {
  padding-top: 20px; }

.news-page .left-news-block,
.news-page .right-news-block {
  margin: 20px 0; }

.news-page .article-banner {
  margin-bottom: 20px; }

.news-page .main-article {
  margin-bottom: 20px; }

.news-page .author-date-divider {
  color: #e4e4e4; }

.news-page .ant-pagination {
  text-align: center; }

/* NEWS PAGE BLOCK STYLES END**/
/* RECENT NEWS BLOCK STYLES BEGIN **/
.recent-news-block {
  margin-bottom: 20px; }
  .recent-news-block .title {
    font-size: 14px; }
  .recent-news-block .recent-article-item {
    margin-bottom: calc(20px / 2);
    padding-bottom: calc(20px / 2); }
    .recent-news-block .recent-article-item:not(:last-child) {
      border-bottom: 1px solid #e4e4e4; }

/* RECENT NEWS BLOCK STYLES END **/
/* ARTICLE PAGE BLOCK STYLES BEGIN **/
.article-page .main-img {
  width: 100%;
  margin-bottom: 20px; }

.article-page .short-content {
  font-weight: bold;
  font-size: 16px; }

.article-page .share-link {
  width: 56px;
  height: 56px;
  background-color: rgba(14, 155, 255, 0.5);
  border-radius: 50%;
  margin: 0 10px 0 0;
  display: inline-block;
  text-align: center;
  line-height: 54px; }
  .article-page .share-link:hover {
    background-color: rgba(14, 155, 255, 0.8); }

/* ARTICLE PAGE BLOCK STYLES END **/
/* TinyMCE STYLES BEGIN **/
.tox-editor-container button,
.tox-statusbar button {
  width: auto !important;
  height: auto !important;
  padding: 0 5px !important;
  line-height: 1.2 !important; }

.tox-notifications-container {
  display: none !important; }

.tox li::before {
  display: none; }

.add-article button.ant-btn-round {
  height: 54px !important;
  border-radius: 27px !important; }

/* TinyMCE STYLES END **/
