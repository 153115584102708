.mapping-wrapper .btn-delete {
  text-transform: uppercase;
  font-size: 14px;
  background: #fff;
  border: 2px solid #e43229;
  color: #e43229;
  font-weight: 600;
  padding: 12px 24px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 48px;
  line-height: 48px;
  border-radius: 24px; }

.mapping-wrapper .btn-modal {
  border-radius: 25px; }

.mapping-wrapper .msg-container {
  max-height: 320px;
  overflow-y: auto; }
  .mapping-wrapper .msg-container .msg {
    text-align: left;
    font-size: 16px;
    line-height: 0.7rem;
    padding: 2px; }

.mapping-wrapper .upload-form {
  background: rgba(41, 102, 244, 0.1);
  border: solid 1px rgba(41, 102, 244, 0.2);
  border-radius: 20px;
  margin: 0 12px 3rem 0;
  padding: 36px;
  width: 100%;
  height: 155px;
  margin-left: calc(40px + 30px); }
  .mapping-wrapper .upload-form h3 {
    color: #38386A;
    font-size: 20px;
    font-weight: 600; }
  .mapping-wrapper .upload-form .btn-upload {
    text-transform: uppercase;
    font-size: 14px;
    background: #0e9bff;
    border: 2px solid #0e9bff;
    color: #fff;
    font-weight: 600;
    border-radius: 24px;
    padding: 0 35px;
    height: 32px; }
  .mapping-wrapper .upload-form .download-template {
    color: #0e9bff;
    font-size: 32px;
    margin-left: 15px; }
  .mapping-wrapper .upload-form input {
    border-radius: 25px;
    white-space: nowrap;
    margin-right: 15px; }
  .mapping-wrapper .upload-form input[type=file]::file-selector-button {
    display: none; }
