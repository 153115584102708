.auth-page {
  background-position: inherit;
  background-repeat: no-repeat;
  background-size: cover;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: white !important; }
  .auth-page h1,
  .auth-page h2 {
    color: white;
    font-weight: bold; }
  .auth-page h5,
  .auth-page .ant-statistic-content-value {
    color: white; }
    .auth-page h5 a,
    .auth-page .ant-statistic-content-value a {
      color: #02baef;
      font-weight: bolder; }
  .auth-page .ant-radio-wrapper {
    background-color: transparent !important;
    background: transparent !important;
    color: white;
    font-weight: bolder; }
  .auth-page .ant-input,
  .auth-page .ant-select-selector {
    background-color: transparent !important;
    background: transparent !important;
    border: 1px solid white !important;
    color: white !important; }
    .auth-page .ant-input::placeholder,
    .auth-page .ant-select-selector::placeholder {
      font-family: "Montserrat";
      color: white !important;
      font-size: 14px !important;
      font-style: normal !important; }
    .auth-page .ant-input .ant-select-selection-placeholder,
    .auth-page .ant-select-selector .ant-select-selection-placeholder {
      font-family: "Montserrat";
      color: white !important;
      font-size: 14px !important;
      font-style: normal !important; }
  .auth-page span.ant-select-arrow {
    color: white; }
  .auth-page .email-link {
    font-size: 18px;
    color: #02baef !important;
    font-weight: bold; }
  .auth-page button {
    background-color: #02baef;
    border-color: #02baef; }
  .auth-page .ant-form-item-explain {
    color: #ff4d4f; }
