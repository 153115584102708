.contact-us-page .ant-collapse {
  background: none !important; }

.contact-us-page .top-line {
  background-color: #2966f4; }
  .contact-us-page .top-line h1 {
    color: white;
    font-weight: 400;
    text-transform: uppercase; }
  @media (max-width: 767px) {
    .contact-us-page .top-line h1 {
      font-size: 30px !important;
      white-space: nowrap; } }

.contact-us-page .contact-form-contaiter h3 {
  text-align: center;
  font-weight: 700;
  color: #38386a; }

.contact-us-page .contact-form-contaiter input {
  background-color: #fafbfd !important;
  border-radius: 10px !important;
  border-color: rgba(175, 186, 192, 0.2) !important; }
  .contact-us-page .contact-form-contaiter input::placeholder {
    font-style: normal !important; }

.contact-us-page .contact-form-contaiter button {
  max-width: 250px;
  background-color: #2966f4;
  border-color: #2966f4; }
  .contact-us-page .contact-form-contaiter button:hover {
    background-color: #2966f4c9;
    border-color: #2966f4c9; }

.contact-us-page .contact-info-box {
  display: flex;
  flex-direction: column;
  align-items: center; }
  .contact-us-page .contact-info-box .info-text p {
    margin-bottom: 0;
    text-align: center;
    font-size: 20px; }
  .contact-us-page .contact-info-box .setup {
    color: #38386a !important;
    text-transform: uppercase; }
    .contact-us-page .contact-info-box .setup p {
      font-size: 38px !important;
      height: 38px !important;
      font-weight: 300;
      margin: 0;
      white-space: nowrap; }
    .contact-us-page .contact-info-box .setup strong {
      height: 38px !important;
      font-size: 38px !important;
      white-space: nowrap; }
    @media (min-width: 768px) and (max-width: 1439px) {
      .contact-us-page .contact-info-box .setup p {
        font-size: 34px !important;
        height: 34px !important; }
      .contact-us-page .contact-info-box .setup strong {
        height: 34px !important;
        font-size: 34px !important; } }
    @media (max-width: 767px) {
      .contact-us-page .contact-info-box .setup p {
        font-size: 26px !important;
        height: 26px !important; }
      .contact-us-page .contact-info-box .setup strong {
        height: 26px !important;
        font-size: 26px !important; }
      .contact-us-page .contact-info-box .setup img {
        height: 75% !important;
        margin-right: 5px !important; } }
  .contact-us-page .contact-info-box ul {
    width: max-content; }
    .contact-us-page .contact-info-box ul li {
      font-family: "Montserrat";
      font-size: 19px;
      margin-bottom: 10px; }
      @media (max-width: 767px) {
        .contact-us-page .contact-info-box ul li {
          font-size: 14px; } }
      @media (min-width: 768px) and (max-width: 1439px) {
        .contact-us-page .contact-info-box ul li {
          font-size: 16px; } }
      .contact-us-page .contact-info-box ul li::before {
        top: 0;
        background: none;
        margin-right: 8px;
        content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHZpZXdCb3g9Ijc1MiAyMzA2IDE4IDE0Ij48aW1hZ2UgeGxpbms6aHJlZj0iZGF0YTppbWFnZS9wbmc7YmFzZTY0LGlWQk9SdzBLR2dvQUFBQU5TVWhFVWdBQUFCSUFBQUFPQ0FZQUFBQWkya3kzQUFBQlEwbEVRVlE0VDZXUlMwdkRRQlNGejl6RW5RdFRFRiticXEwN1FWMjVGS1RKci9HeEVrUVJ4Vi9nQTdkdXUzTmhiV0tNK0c4VXJCQWkzV251eU9UVlJHSkQ2Nnp1Y085OGM4NjVBdjg1YnRBVWttOGs2RmlNelhHREprbTJJYkFNNEcwOGtJS0F1d0Fha1JBcERrWUhPVUdEaUYwQWk0bWJmVGFOaTlGQTN1Y0toV0Uzc2FNNEVVUVZNY2p0emNPY2ZoMmFWNnprRWNCU1hrbjZSdWhQL2haTHRDSEZMVnRUaDZVd3BZUkRPNE1JN0hMTHVNclBDdUg2bmdDMjQ5Qnd4cFp4V29EOWh1VHNGRUI0L2xpZ1VMc0hzSjQwenRrMFRxSTZoaWc3OWVoZW9pU3pGaFZPYjQ0MHZRT0pqYmdoajFqd0hVbXRrOW1SMkdQTHVQd3J4OEhXdlA0TThaY0RZQzBaN2dPWXJGSlNWSlRlWHQ1bjZYdmlZYUJNMlpFNzNLcGREOTFvdHY3OGxMSkp1c3BsRlJWMmltR1hmV1g3ZFkza1ptalYybFZLMHY0UHhycDNvYk5qek9FQUFBQUFTVVZPUks1Q1lJST0iIHdpZHRoPSIxOCIgaGVpZ2h0PSIxNCIgeD0iNzUyIiB5PSIyMzA2Ii8+PC9zdmc+); }

.contact-us-page .ccpa-faq {
  background-color: #fafbfd; }

.contact-us-page .what-is-ccpa {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center; }
  .contact-us-page .what-is-ccpa p {
    font-size: 22px !important;
    color: #38386a; }
  @media (max-width: 767px) {
    .contact-us-page .what-is-ccpa p {
      font-size: 18px !important; } }
  .contact-us-page .what-is-ccpa h3 {
    font-weight: bold !important;
    color: #38386a; }

.contact-us-page .faq-link {
  font-weight: 500;
  text-transform: uppercase;
  border-radius: 18px;
  padding: 12px 40px;
  font-size: 16px;
  background-color: #02baef !important;
  border-color: #02baef !important; }
  .contact-us-page .faq-link:hover {
    background-color: #60ddff !important;
    border-color: #60ddff !important; }
