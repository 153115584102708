.faq-page {
  font-size: 20px; }
  .faq-page h2,
  .faq-page h3 {
    font-weight: bold; }
  .faq-page p {
    font-weight: 500; }
  .faq-page ol li {
    font-weight: 500; }
  .faq-page ul strong::before {
    content: none;
    font-weight: bold; }
  .faq-page ul li {
    font-weight: 500; }
  .faq-page ul ::before {
    content: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAASCAYAAABb0P4QAAAEYklEQVQ4jYVUe4hUVRz+nXPPfc5jZ/fu7uw0mru5uq+w3XYl6OGGUeKjEKNAiAyRUkIkNMpgDdKyMMTECEKJhALBIEqlsMAXZdm2LrquO+2qq/uc3dmZ2blzn+feE2fEiCT84P714/vud77znR8SNR08MwMc0TmPgL5wOagV82WEhJeIGlsrR5OdWNQInwdu0XcK46epnf0aGHxVnOq3M6kTUBjrLvFFrQIIYz7cge8VQdXrN0dqWvcDFiCgDuRvne/xHSOFEAZBDi+MJDuWquW1SxljB1lAt0xRa/8dPtci1Mr9I1j3ZNcxvWHlysJ4z6BrTG71ncJ32aGfwM7dBIQFUMrrgAtjUXtWjib3VrU8/wkmyrLLR15cyfnUygNqXP05UDsHSqzulFbV2GmM9+5izO/yjDQQJaZKkfhqz5xpAgQgqpX9bmH0W+oWLFHVAQC9F0l2dFmZ1BlzeqBTEENARLUSyuY+ukeJ1XZmUsd3XDv5zk6tuhnqlr77AWLwBhJlRdUXlE4QeBaIWrmNibrv+s9d2wuj3Tvql+919IYVu8KJ1o+94tQ27FmZ+1xjYpuVu9HrWdmd1JmFSKL1uBKr3Q5YUALPBmplS19AbUBYUqRwzduh+KITQUB57u/b2Ru9npHeakxcigsPPLXzQ0yUxU5+eEVh7M+J6pY1byXaXtlkZ6/xmOEusAA8awb0BcsWyGVzHCszeE7UKs4TObKRqBUK6tj4u+l7RcGcTslSqFrQ9IWG7xkKCyjP6G7B2/cJCIuARdUxp1Mh15jwQ1VNriBHHAwIq4FnXzHTV8DM/NUaUFvhLv5fDG7PWACMurI9c/1hc2qAV+wSMBbGnIYwMXzPBNeYLAOM7yH2L2AB3OJkGfMdrlHgleLFZoCgKRR/EERN7+XBA+B7avF0GbUgkmi76FkZ7qoFEA4wQsJpQQzpcjihIUAZauV/5CW+FxAmQK3cScb8aSkc10LVzZXpy0d+wNTO7yZKOVBn9qPs9VOABLKByBGfyNE7Pv7rC/iMyFEfC9L6/K1fOXc3JjIE1N3DrQxJmv6CVtW4CoAdlaPJITt/83tqZZ/RKhvKMVFuPzdB4i+HxwLFyb4bXjH9NFHLBhAmzeGa1sPGeE+/MzvyJmp47jMQpFAyXNM2osTuh4mLh5tGzn96lW+O2LzHt2jVLaskrbKe+3PNzJCZ7juWGz67zy2mIbl4Y2OifX2/OzsGs6MX5lI7P4IWb/oDAt8D3y08IWr6Gd6vse5Dm8d7vjhApChEku2lLcJvHiMBCqMXgLpFqGlb93qibd0B/iPPnO4kcvQMEiRAHa/9xgsKTv4WWDODTVpV8zdl8x5ryg+fY9bMtUMYk7NucWqQAQMpVD2fMbpEjdWtj9Uuwbnhs1fN6dQaVa/vl8Px0rpD7a/+AjwnamfBmOyDqb6joMTufznRvmG7EpvXWFpvpaJzkwiIWg5O7ubAWPfB3ebM0JfxRWshXPMQEFGDwHfhb/blBJRsqNUOAAAAAElFTkSuQmCC);
    top: 0;
    margin: 0;
    left: 0;
    position: absolute; }

.faq-header {
  display: table;
  width: 100%;
  background: linear-gradient(rgba(0, 0, 255, 0.5), rgba(0, 0, 255, 0.5)), url(/FAQ.jpg);
  background-position: inherit;
  background-repeat: no-repeat;
  background-size: cover;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column; }
  .faq-header h1 {
    color: white;
    text-align: center;
    text-transform: uppercase; }

.hash_liks_box {
  background-color: #02baef40; }
  .hash_liks_box a {
    font-size: 35px;
    font-weight: bold;
    color: #010594; }
