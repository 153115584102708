.alerts-contacts-page {
  flex-direction: column; }
  .alerts-contacts-page .description {
    flex-direction: column;
    padding: 0 12px; }
    .alerts-contacts-page .description p {
      font-size: 17px;
      color: #7D7972; }
    .alerts-contacts-page .description .divider {
      height: 4px;
      background: #F4F5F5;
      margin-bottom: 30px; }
  .alerts-contacts-page .notify-form {
    background: rgba(41, 102, 244, 0.1);
    border: solid 1px rgba(41, 102, 244, 0.2);
    border-radius: 20px;
    margin: 0 12px;
    padding: 36px; }
    .alerts-contacts-page .notify-form h3 {
      color: #38386A;
      font-size: 24px;
      font-weight: 600; }
    .alerts-contacts-page .notify-form .ant-radio-group {
      display: flex;
      margin: 24px 0; }
      .alerts-contacts-page .notify-form .ant-radio-group label {
        color: #38386A;
        font-size: 16px;
        font-weight: 600;
        display: flex;
        align-items: center;
        width: fit-content; }
    .alerts-contacts-page .notify-form input {
      height: 45px;
      width: 400px;
      border-radius: 30px; }
      .alerts-contacts-page .notify-form input::placeholder {
        font-style: italic; }
    .alerts-contacts-page .notify-form .verify-block {
      width: 400px; }
    .alerts-contacts-page .notify-form .warning img {
      height: 30px;
      margin-right: 8px; }
    .alerts-contacts-page .notify-form .warning p {
      font-size: 16px;
      color: #38386A;
      margin-bottom: 0; }
    .alerts-contacts-page .notify-form button.to-confirm {
      color: #ff0000; }
    .alerts-contacts-page .notify-form button svg {
      color: #ff0000; }
    .alerts-contacts-page .notify-form .confirmed {
      color: #5bad54;
      display: flex; }
    .alerts-contacts-page .notify-form .unconfirmed {
      color: #ffcb31;
      display: flex; }
  .alerts-contacts-page .send-button {
    background: #02BAEF;
    color: white;
    height: 52px;
    width: 136px;
    border-radius: 45px;
    font-weight: 600;
    margin: 0 12px; }
