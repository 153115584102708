.landing-page .slogan {
  width: 100%;
  display: flex;
  align-items: center; }
  .landing-page .slogan video {
    position: relative;
    z-index: 0;
    max-height: 700px; }
  .landing-page .slogan .play-btn {
    color: #fff;
    height: auto;
    width: auto; }
    @media (max-width: 767px) {
      .landing-page .slogan .play-btn {
        margin: 1rem !important; } }
  .landing-page .slogan .hidden {
    opacity: 0.1; }
  .landing-page .slogan .hidden-video {
    opacity: 0.3; }
  @media (max-width: 767px) {
    .landing-page .slogan {
      min-height: 380px; } }
  .landing-page .slogan:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0; }
  .landing-page .slogan .title {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    z-index: 10;
    padding: 140px 0;
    top: 2%;
    left: 0; }
    @media (max-width: 767px) {
      .landing-page .slogan .title {
        padding: 0; } }
    @media (min-width: 768px) and (max-width: 1439px) {
      .landing-page .slogan .title {
        padding: 0; } }
    @media (max-width: 767px) {
      .landing-page .slogan .title {
        top: 0; } }
    .landing-page .slogan .title p {
      font-size: 52px;
      color: #2c3959;
      line-height: 1.25 !important;
      font-weight: 500; }
      @media (max-width: 767px) {
        .landing-page .slogan .title p {
          font-size: 28px; } }
      .landing-page .slogan .title p.subtitle {
        font-size: 15px !important; }
        @media (max-width: 767px) {
          .landing-page .slogan .title p.subtitle {
            font-size: 12px !important; } }
        @media (min-width: 768px) and (max-width: 1439px) {
          .landing-page .slogan .title p.subtitle {
            font-size: 12px !important; } }

.landing-page .step-image {
  width: 80%; }

.landing-page .step-image1-1 {
  width: 80%;
  margin-bottom: 20%; }

.landing-page .step-image1-2 {
  position: absolute !important;
  width: 80%;
  margin-left: 20%;
  margin-top: 20%; }

.landing-page .logos {
  align-items: center;
  justify-content: center;
  background: #f7f8f9;
  height: 95px; }
  @media (max-width: 767px) {
    .landing-page .logos {
      height: 70px; } }
  .landing-page .logos img {
    margin: 0 2%; }
    @media (min-width: 768px) and (max-width: 1439px) {
      .landing-page .logos img {
        margin: 0 5px; } }
    @media (max-width: 767px) {
      .landing-page .logos img {
        height: 20px;
        margin: 0 4px; } }

.landing-page .information {
  background: #ecf7ff;
  padding: 80px 15px; }
  @media (max-width: 767px) {
    .landing-page .information {
      flex-direction: column; } }
  @media (min-width: 768px) and (max-width: 1439px) {
    .landing-page .information {
      flex-direction: column; } }

.landing-page .notice,
.landing-page .info-about {
  padding: 70px 0;
  background: #ecf7ff;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #959cac;
  padding: 80px 15px; }
  .landing-page .notice > div,
  .landing-page .info-about > div {
    max-width: 900px; }

.landing-page .info-about {
  background: #fff;
  color: #000; }

.landing-page .founder-block {
  background: linear-gradient(rgba(38, 93, 221, 0.5), rgba(38, 93, 221, 0.5)), url(/122cf088081b316a2f7fb93ca0537ff6.png);
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 600px; }
  @media (max-width: 767px) {
    .landing-page .founder-block {
      min-height: 250px;
      max-height: 250px;
      margin-top: 3rem; } }
  .landing-page .founder-block .founder-text {
    color: #fff; }
    @media (max-width: 767px) {
      .landing-page .founder-block .founder-text {
        margin-left: 1rem; } }
    .landing-page .founder-block .founder-text h1 {
      color: #fff;
      font-weight: 600;
      margin-right: 2rem;
      line-height: 4rem;
      letter-spacing: 4px; }
      @media (max-width: 767px) {
        .landing-page .founder-block .founder-text h1 {
          line-height: 2rem;
          letter-spacing: 2px;
          font-size: 24px; } }
      @media (min-width: 768px) and (max-width: 1439px) {
        .landing-page .founder-block .founder-text h1 {
          line-height: 3rem;
          letter-spacing: 3px;
          font-size: 36px; } }
