.blue-line {
  height: 2px;
  width: 10%;
  background: #0e9bff; }

.ant-tabs-tab-btn {
  font-size: 20px; }

.search-by-email-page .search-block {
  border-bottom: solid 1px #0e9bff;
  max-width: max-content; }

.search-by-email-page tr {
  background: #ecf7ff; }

.search-container {
  min-height: 680px; }

.question {
  font-weight: 500;
  font-style: italic; }

.confirm-delete {
  max-width: 200px; }

.deleted-user {
  color: #e43229;
  font-weight: 400; }

.opt-out-modal {
  max-width: 600px !important;
  padding: 12px; }

.info-table {
  max-width: max-content; }
