.list-owners-and-mailers > div {
  padding: 15px; }

.list-owners-and-mailers .slogan {
  width: 100%;
  background-position: 50%;
  background-size: cover;
  background-attachment: fixed;
  position: relative;
  background-image: url(/3c7c9c7ec55f7e4184839cbcc0bdb440.png); }
  .list-owners-and-mailers .slogan:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0; }
  .list-owners-and-mailers .slogan .main-text {
    color: #fff;
    font-size: 48px;
    line-height: 1.52; }
    .list-owners-and-mailers .slogan .main-text > div {
      padding: 50px; }
      @media (min-width: 768px) and (max-width: 1439px) {
        .list-owners-and-mailers .slogan .main-text > div {
          padding: 0; } }
    @media (min-width: 768px) and (max-width: 1439px) {
      .list-owners-and-mailers .slogan .main-text {
        font-size: 38px; } }
    @media (max-width: 767px) {
      .list-owners-and-mailers .slogan .main-text {
        font-size: 28px;
        text-align: center; } }
  .list-owners-and-mailers .slogan .main-form {
    height: auto;
    padding: 40px;
    margin: 20px;
    background: #fff;
    border-radius: 4px;
    box-shadow: 0 11px 38px -5px rgba(1, 39, 88, 0.18); }
    @media (max-width: 767px) {
      .list-owners-and-mailers .slogan .main-form {
        margin: 4px; } }
    .list-owners-and-mailers .slogan .main-form .title {
      font-size: 32px;
      margin: 24px 0; }

.list-owners-and-mailers .title-bottom {
  color: #01132a;
  font-size: 2.5rem;
  padding: 18px 0; }
  @media (max-width: 767px) {
    .list-owners-and-mailers .title-bottom {
      font-size: 1.8rem; } }
  .list-owners-and-mailers .title-bottom img {
    align-self: center;
    width: 80%;
    height: auto; }
  @media (max-width: 767px) {
    .list-owners-and-mailers .title-bottom img {
      align-self: center;
      width: 50%;
      height: auto; } }
  .list-owners-and-mailers .title-bottom .blue-line {
    height: 2px;
    width: 10%;
    background: #0e9bff; }

.list-owners-and-mailers .card-image-2 {
  background-image: url(/bg-box-2.jpg);
  min-height: 500px;
  background-position: 50%;
  background-size: cover;
  border-radius: 4px;
  height: 100%;
  margin-left: -90px; }
  @media (max-width: 767px) {
    .list-owners-and-mailers .card-image-2 {
      margin: 0; } }
  @media (min-width: 768px) and (max-width: 1439px) {
    .list-owners-and-mailers .card-image-2 {
      margin: 0; } }

.list-owners-and-mailers .card-image-3 {
  background-image: url(/bg-box-3.jpg);
  min-height: 500px;
  background-position: 50%;
  background-size: cover;
  border-radius: 4px;
  height: 100%;
  margin-right: -90px; }
  @media (max-width: 767px) {
    .list-owners-and-mailers .card-image-3 {
      margin: 0; } }
  @media (min-width: 768px) and (max-width: 1439px) {
    .list-owners-and-mailers .card-image-3 {
      margin: 0; } }
  .list-owners-and-mailers .card-image-3 .logo-box {
    width: 290px;
    height: 290px;
    background-color: #0e9bff;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 120px;
    bottom: -120px;
    border-radius: 3px;
    box-shadow: 0 11px 38px -5px rgba(1, 39, 88, 0.18); }
    .list-owners-and-mailers .card-image-3 .logo-box img {
      max-width: 170px; }
    @media (max-width: 767px) {
      .list-owners-and-mailers .card-image-3 .logo-box {
        display: none; } }
    @media (min-width: 768px) and (max-width: 1439px) {
      .list-owners-and-mailers .card-image-3 .logo-box {
        display: none; } }

.list-owners-and-mailers .white-block {
  padding: 90px; }
  @media (max-width: 767px) {
    .list-owners-and-mailers .white-block {
      padding: 10px; } }
  .list-owners-and-mailers .white-block .card-shadow {
    border-radius: 4px;
    box-shadow: 0 11px 38px -5px rgba(1, 39, 88, 0.18);
    background-color: #fff;
    height: 100%; }
    .list-owners-and-mailers .white-block .card-shadow img {
      height: 48px;
      margin: 24px; }
    .list-owners-and-mailers .white-block .card-shadow .text {
      font-size: 18px;
      line-height: 1.65; }
    .list-owners-and-mailers .white-block .card-shadow .card-image {
      background-image: url(/bg-box-1.jpg);
      min-height: 500px;
      background-position: 50%;
      background-size: cover;
      border-radius: 4px;
      height: 100%; }

.list-owners-and-mailers .blue-block {
  padding: 90px;
  background: #ecf7ff;
  color: #495057;
  width: 100% !important;
  max-width: 100%; }
  @media (max-width: 767px) {
    .list-owners-and-mailers .blue-block {
      padding: 20px; } }
  .list-owners-and-mailers .blue-block div {
    width: auto; }
  .list-owners-and-mailers .blue-block .title {
    font-size: 20px;
    width: auto;
    margin-bottom: 1rem;
    text-align: center; }
  .list-owners-and-mailers .blue-block .text {
    max-width: 1000px;
    width: auto;
    text-align: center; }
