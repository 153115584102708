.terms-of-use-page p {
  font-weight: 600;
  font-size: 22px; }

@media (max-width: 767px) {
  .terms-of-use-page p {
    font-size: 18px !important; } }

.terms-of-use-header {
  display: table;
  width: 100%;
  background: linear-gradient(rgba(0, 0, 255, 0.5), rgba(0, 0, 255, 0.5)), url(/TermsOfUse.jpg);
  background-position: inherit;
  background-repeat: no-repeat;
  background-size: cover;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column; }
  .terms-of-use-header h1 {
    color: white;
    text-align: center;
    text-transform: uppercase; }
  .terms-of-use-header h2 {
    color: white;
    font-weight: lighter;
    text-align: center; }
