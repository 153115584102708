@charset "UTF-8";
.steps-content {
  min-height: 200px;
  text-align: center;
  padding-top: 50px; }

.steps-action {
  margin-top: 24px; }

.upload-modal p {
  color: #0e9bff;
  text-align: center; }

.upload-modal h2 {
  text-align: center; }

.mapping-input {
  width: 560px; }

.mappings .ant-checkbox-wrapper {
  margin-top: 1em;
  align-items: center;
  display: flex;
  justify-content: left; }

.save-btn {
  width: 150px !important;
  background: #02baef !important;
  border: solid 1px #02baef !important; }

div .ant-card {
  border-radius: 10px !important;
  border-width: 0.1px;
  border-color: rgba(41, 102, 244, 0.1);
  border-style: solid;
  background-color: #95b3f91a;
  height: 100%; }
  div .ant-card .ant-card-head {
    border-color: rgba(41, 102, 244, 0.1); }
  div .ant-card .ant-card-head-title {
    font-weight: bold;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center; }
  div .ant-card .ant-select-selector {
    height: 48px;
    border-radius: 24px !important;
    line-height: 48px;
    padding: 0 4px;
    background-color: white;
    border-radius: 8px;
    border: 1px solid #c7cace; }
    div .ant-card .ant-select-selector .ant-select-selection-search-input {
      height: 46px;
      padding-left: 0.8rem; }
    div .ant-card .ant-select-selector .ant-select-selection-item-remove {
      display: flex;
      align-items: center; }

.alert-card {
  background-color: rgba(255, 159, 50, 0.08) !important;
  border-color: #ffc30070 !important; }
  .alert-card .ant-card-head {
    border-color: #ffc30070 !important; }

.info-box {
  padding: 20px;
  height: 100%;
  border-width: 0.1px;
  border-color: rgba(41, 102, 244, 0.1);
  border-style: solid;
  border-radius: 10px;
  background-color: #95b3f91a; }
  .info-box ul {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between; }
    .info-box ul li {
      font-size: 14px;
      color: #38386a;
      font-weight: 700;
      padding-left: 15px; }
    .info-box ul ::before {
      content: "❯";
      color: #02baef;
      top: 0;
      margin: 0;
      left: 0;
      position: absolute; }

div .ant-upload.ant-upload-drag {
  border-width: 0.3px;
  border-color: rgba(42, 102, 244, 0.3);
  border-style: solid;
  border-radius: 15px;
  background-color: rgba(41, 102, 244, 0.031);
  min-height: 286px;
  font-weight: bold; }
  div .ant-upload.ant-upload-drag h5 {
    font-weight: bold;
    color: #38386a; }
  div .ant-upload.ant-upload-drag button {
    margin-left: auto;
    margin-right: auto;
    margin-top: 0.3rem;
    margin-bottom: 0.3rem;
    font-size: 16px; }

.ant-upload-list {
  text-align: initial; }
  .ant-upload-list > div {
    margin-bottom: 15px;
    padding-left: 25px;
    padding-right: 25px;
    padding-top: auto;
    padding-bottom: auto;
    height: 45px;
    border-width: 0.3px;
    border-color: rgba(42, 102, 244, 0.3);
    border-style: solid;
    border-radius: 25px;
    background-color: rgba(41, 102, 244, 0.031); }
    .ant-upload-list > div .ant-btn-icon-only {
      padding: 0 !important;
      height: auto !important; }
    .ant-upload-list > div .ant-upload-list-item-info {
      font-size: large; }
      .ant-upload-list > div .ant-upload-list-item-info:hover {
        background-color: #00000000 !important; }

.next_btn {
  background-color: #02baef !important;
  border-color: #02baef !important; }
  .next_btn:hover {
    background-color: #60ddff !important;
    border-color: #60ddff !important; }

.back_btn {
  background-color: rgba(0, 0, 0, 0) !important;
  border-color: rgba(0, 0, 0, 0) !important;
  color: #5a5b82; }
  .back_btn:hover {
    color: #38386a; }

div .list-item-info {
  margin-bottom: 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 25px;
  padding-right: 25px;
  height: 45px;
  border-width: 0.3px;
  border-color: rgba(42, 102, 244, 0.3);
  border-style: solid;
  border-radius: 25px;
  background-color: rgba(41, 102, 244, 0.031); }

.ant-steps.ant-steps-horizontal .ant-steps-item-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: fit-content; }

.ant-steps-item-title::after {
  display: none !important; }

.last-step .ant-steps-item-icon::after {
  display: none !important; }

.first-step .ant-steps-item-icon::before {
  display: none !important; }

.ant-form-item-label label {
  margin-top: 7px; }

.ant-steps-item-icon {
  margin: 10px; }

.ant-steps-item-container {
  position: relative; }
  .ant-steps-item-container::after {
    content: "";
    display: block;
    width: 9999px;
    height: 1px;
    position: absolute;
    left: 100%;
    top: 30%;
    background-color: #f0f0f0; }

.ant-steps-item-finish .ant-steps-item-content {
  opacity: 0; }

.ant-steps-item-finish .ant-steps-item-icon {
  background-color: #00000000 !important;
  padding: 30px;
  border: 1px dashed #4deb00 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  border-spacing: 25px; }
  .ant-steps-item-finish .ant-steps-item-icon .ant-steps-icon {
    border-radius: 20px;
    border-color: #4deb00;
    background-color: #4deb00;
    color: white !important;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 10px;
    font-size: 1.3em;
    font-family: "Helvetica";
    font-weight: bold !important; }

.ant-steps-item-active .ant-steps-item-icon {
  background-color: #00000000 !important;
  padding: 30px;
  border: 1px dashed #2966f4 !important;
  display: flex;
  justify-content: center;
  align-items: center; }
  .ant-steps-item-active .ant-steps-item-icon .ant-steps-item-content {
    display: inline-block !important;
    font-weight: bold !important; }
  .ant-steps-item-active .ant-steps-item-icon .ant-steps-icon {
    border-radius: 20px;
    border-color: #2966f4;
    background-color: #2966f4;
    color: white !important;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 1.3em;
    font-family: "Helvetica";
    font-weight: bold !important; }

.ant-steps-item-wait .ant-steps-item-icon {
  background-color: #00000000 !important;
  padding: 30px;
  border: 1px dashed #c3c8cc !important;
  display: flex;
  justify-content: center;
  align-items: center; }
  .ant-steps-item-wait .ant-steps-item-icon .ant-steps-icon {
    border-radius: 20px;
    border-color: #c3c8cc;
    background-color: #c3c8cc;
    color: white !important;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 1.3em;
    font-family: "Helvetica";
    font-weight: bold !important; }
